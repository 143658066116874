.team-member-list {
    margin-bottom: 30px !important;
    text-align: left;
}

.team-member-list-item {
    list-style-type: none;
    padding: 0;
    display: block;
    clear: both;
    overflow: hidden;
    margin: 0 auto 8px;
}

.team-member-list-item__name {
    min-width: 100px;
    display: inline-block;
    margin-right: 15px;
}

.team-member-list-item button {
    display: inline-block;
    margin-right: 5px;
}

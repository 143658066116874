@import "../../styles/breakpoints";
@import "../../styles/colors";


.request-pending {
    color: #fa8c16;
    display: block;
}

.team-captain {
    display: block;
    color: #aaa;
}

.ant-table-column-sorter {
    display: flex;
}


.leaderboard__round-heading {
    display: inline-block;
    text-align: left;
    border-bottom: dashed 1px $color-mine;
}

.leaderboard__round-name {
    display: inline-block;
}


.leaderboard {
    .ant-table-column-sorter {
        display: none;
    }
    th {
        vertical-align: baseline;

        &:first-child, &:last-child {
            vertical-align: middle;
        }
    }

    .ant-table-column-sorters {
        padding: 16px 4px;
    }
}

.team-heading {
    min-width: 120px;
}

$breakpoint-xl: 1920px;
$breakpoint-lg: 1280px;
$breakpoint-md: 960px;
$breakpoint-sm: 600px;
$breakpoint-xs: 0;

$breakpoints: (
  "xl\\+" "screen and (min-width: #{$breakpoint-xl})",
  "lg\\+" "screen and (min-width: #{$breakpoint-lg})",
  "md\\+" "screen and (min-width: #{$breakpoint-md})",
  "sm\\+" "screen and (min-width: #{$breakpoint-sm})",
  "xs\\+" "screen and (min-width: #{$breakpoint-xs})",

  "xl-" "screen and (max-width: #{$breakpoint-xl - 1px})",
  "lg-" "screen and (max-width: #{$breakpoint-lg - 1px})",
  "md-" "screen and (max-width: #{$breakpoint-md - 1px})",
  "sm-" "screen and (max-width: #{$breakpoint-sm - 1px})",

  "lg" "screen and (min-width: #{$breakpoint-lg - 1px}) and (max-width: #{$breakpoint-xl - 1px})",
  "md" "screen and (min-width: #{$breakpoint-md - 1px}) and (max-width: #{$breakpoint-lg - 1px})",
  "sm" "screen and (min-width: #{$breakpoint-sm - 1px}) and (max-width: #{$breakpoint-md - 1px})",
);


@mixin between($breakpoint-min, $breakpoint-max) {
  @media screen and (min-width: #{$breakpoint-min}) and (max-width: #{$breakpoint-max}) {
    @content;
  }
}

@mixin to($breakpoint) {
  @media screen and (max-width: #{$breakpoint}) {
    @content;
  }
}

@mixin from($breakpoint) {
  @media screen and (min-width: #{$breakpoint}) {
    @content;
  }
}

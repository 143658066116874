.quiz-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.quiz-list__title {
    margin-top: 40px;
    margin-bottom: 20px;
}

.quiz-list__item-content p:last-child {
    margin-bottom: 0;
}

@import "../styles/breakpoints";

.quiz-page {
    max-width: 800px;
    margin: 0 auto;

    @include from($breakpoint-sm) {
        margin-top: 24px;
    }
}

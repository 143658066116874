@import "../../styles/breakpoints";

.quiz {
    @include from($breakpoint-sm) {
        margin-top: 24px;
    }
}

.quiz__heading {
    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;

    @include from($breakpoint-sm) {
        font-size: 2.5rem;
    }
}

.quiz__team-heading {
    text-align: left;
    font-size: 1.25rem;

    @include from($breakpoint-sm) {
        font-size: 1.5rem;
    }
}

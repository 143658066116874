.manage-question__question {
    margin-bottom: 10px;
}

.manage-question__release {
    display: block;
}

.manage-question__unrelease {
    display: block;
}

.manage-question__answer {
    display: block;
    min-height: 44px;
    margin-bottom: 5px;
}

.manage-question__points {
    display: block;
    width: 50px;
    margin: 0 auto;
    text-align: center;

}

.manage-question__points--unmarked {
    border: 1px solid orange;
}

.manage-question__image {
    max-width: 400px;
    display: block;
    margin-top: 8px;
    margin-bottom: 10px;
}

$color-white: #FFFFFF;
$color-black: #000000;

// Blues
$color-dodger: #1890FF;

// Greens
$color-atlantis: #73D13D;

// Greys
$color-alabaster: #FAFAFA;
$color-gallery: #EEEEEE;
$color-alto: #DDDDDD;
$color-silver: #CCCCCC;
$color-mine: #333333;

@import "../../styles/breakpoints";


.question {
    display: flex;
    width: 100%;

    // @include from($breakpoint-sm) {
    //     flex-direction: row;
    // }
}

.question__header {
    display: flex;
    flex-wrap: none;
    align-items: center;
    align-content: flex-start;
    padding: 10px 0;
    justify-content: space-between;

    // @include from($breakpoint-sm) {
    //     flex-wrap: wrap;
    //     justify-content: center;
    //     align-items: flex-start;
    //     padding: 0 10px 0 0;
    // }
}

.question__number {
    font-size: 24px;
    min-width: 32px;
    margin-right: 8px;
}

.question__content {
    flex: 1 1 100%;
    padding-top: 0;
    box-sizing: border-box;

    // @include from($breakpoint-sm) {
    //     padding-top: 5px;
    // }
}

.question__question {
    display: block;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 10px;
}

.question__picture {
    display: flex;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 15px;

    img {
        max-height: 500px;
        object-fit: contain;
    }
}

.question__input {
    width: 100%;
    display: block;
}

.question__teamanswer {
    line-height: 24px;
    display: inline-block;
    padding: 3px 10px;
    width: 100%;
    color: #666;
    background-color: #f5f5f5;
    min-height: 32px;
    margin-bottom: 10px;
    &--completed {
        color: #333;
        background-color: #d9d9d9;
    }
}

.question__answer {
    margin-bottom: 0;
}

@import "../../styles/breakpoints";


.question-points {
    margin-left: 24px;
}

.question-points__score {
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    border: 2px solid;
    display: inline-block;
    padding: 5px 10px;

    @include from($breakpoint-sm) {
        margin-top: inherit;
    }

    &--correct {
        color: #389e0d;
    }
    &--incorrect {
        color: #f5222d;
    }
    &--partially-correct {
        color: orange;
    }
}

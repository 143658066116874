@import "../../styles/breakpoints";
@import "../../styles/colors";


.round-selector {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.round-selector__number {
    @include from($breakpoint-sm) {
        &::before {
            content: "Round "
        }
    }
}

.round-selector__points {
    margin-left: 10px;
    border: 1px solid $color-atlantis;
    color: $color-atlantis;
    padding: 2px 4px;
    min-width: 24px;
    display: inline-block;
    text-align: center;
}

.round-selector__control {
    display: inline-block;
    height: 44px;
    width: 44px;
    border: solid 2px $color-alto;
    padding: 6px;
    box-sizing: border-box;
    color: $color-dodger;
    background-color: $color-alabaster;
    text-align: center;
    cursor: pointer;
    transition: all 1s;

    &:focus {
        outline: 0;
    }

    &:hover {
        background-color: $color-dodger;
        color: $color-white;
        border-color: $color-alto;
    }

    &--disabled {
        cursor: default;
        background-color: $color-gallery;
        color: $color-alto;
        border-color: $color-alto;

        &:hover {
            background-color: $color-gallery;
            color: $color-alto;
            border-color: $color-alto;
        }
    }

    &--first {
        margin-right: 5px;
    }

    &--previous {
        margin-right: 5px;
    }

    &--next {
        margin-left: 5px;
    }

    &--last {
        margin-left: 5px;
    }

    &--first, &--last {
        display: none;

        @include from($breakpoint-sm) {
            display: inline-block;
        }
    }

}

.round-selector__content {
    flex: 1 1 80%;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
}

.round-selector__title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border: solid 2px $color-alto;
    color: $color-dodger;
    font-weight: bold;
    background-color: $color-alabaster;
    transition: all 1s;
    box-sizing: border-box;
    min-height: 44px;

    &:hover {
        background-color: $color-dodger;
        color: $color-white;
    }
}


.round-selector__option-list {
    border: solid 2px $color-alto;
    background-color: $color-alabaster;
}

.round-selector__option {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-top: solid 1px $color-alto;
    min-height: 44px;
    box-sizing: border-box;
    transition: all 1s;
    font-weight: bold;
    color: $color-dodger;

    &:first-child {
        border-top: none;
    }

    &:hover {
        background-color: $color-dodger;
        color: $color-white;
    }

    &--active {
        background-color: $color-dodger;
        color: $color-white;
    }
}
